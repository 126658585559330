import React from 'react';
import { makeStyles } from '@material-ui/core/styles/';
import BackgroundImage from 'gatsby-background-image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
// import vButton from '../images/vButton.png';

const useStyles = makeStyles(theme => ({
  heroImage: {
    // height: '84vh',
    height: '100vh',
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'container',
    alignContent: 'center',
    alignItems: 'center',
  },
  slideNr: {
    fontSize: '140px',
    color: 'white',
    position: 'absolute',
    bottom: '0',
    right: '0',
    paddingRight: 80,
    '@media (max-width:1920px)': {
      fontSize: '8vw',
    },
    '@media (max-width:600px)': {
      fontSize: '6vh',
      paddingRight: 40,
    },
  },

  // LEFT HEADER
  headerLeft: {
    fontSize: '8vw',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: '0.86em',
    marginBottom: '0px',
    wordSpacing: '100vw',
    width: 100,
    margin: 'auto',
    textTransform: 'uppercase',
    '@media (max-width:600px)': {
      fontSize: '8vh',
    },
  },

  // CENTER HEADER
  headerCenterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerCenter: {
    fontSize: '140px',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '0.86em',
    marginBottom: '0px',
    wordSpacing: '80vw',
    textTransform: 'uppercase',
    '@media (max-width:1920px)': {
      fontSize: '8vw',
    },
    '@media (max-width:600px)': {
      fontSize: '6vh',
    },
  },

  subheader: {
    color: 'white',
    maxWidth: 600,
    textAlign: 'left',
    paddingLeft: 140,
    paddingTop: 20,
  },

  // LEFT HEADER 2
  headerLeftTwo: {
    fontSize: '8vw',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: '0.86em',
    marginBottom: '0px',
    wordSpacing: '100vw',
    width: 100,
    margin: 'auto',
    '@media (max-width:600px)': {
      fontSize: '8vh',
    },
  },

  // RIGHT HEADER
  topContainer: {
    width: '80%',
    '@media (min-width:2500px)': {},
  },
  headerRight: {
    fontSize: '140px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: '0.86em',
    marginBottom: '0px',
    paddingTop: 20,
    textTransform: 'uppercase',
    '@media (min-width:2500px)': {
      paddingTop: 0,
    },
    '@media (max-width:1920px)': {
      fontSize: '8vw',
    },
    '@media (max-width:600px)': {
      fontSize: '36px',
    },
  },
  subHeaderLeftInner: {
    color: 'white',
    maxWidth: 500,
    textAlign: 'left',
    paddingTop: 20,
    marginBottom: 30,
    '@media (min-width:2500px)': {
      fontSize: 22,
    },
    '@media (max-width:1200px)': {
      paddingRight: 40,
    },
    '@media (max-width:600px)': {
      paddingRight: 40,
      fontSize: 14,
    },
  },
  subHeaderWrapper: {
    '@media (min-width:2500px)': {
      width: '100%',
    },
  },

  // Other
  slideNumberContainer: {
    color: 'white',
    alignSelf: 'flex-end',
    paddingRight: '10px',
    textAlign: 'right',
    alignContent: 'flex-end',
    '@media (max-width:600px)': {
      visibility: 'hidden',
    },
  },
  slideNumber: {
    paddingRight: 26,
    paddingTop: 140,
    '@media (max-width:600px)': {
      visibility: 'hidden',
      paddingTop: 0,
    },
  },
}));

const HeroCarousel = ({ data }) => {
  const classes = useStyles();
  // const numberOfSlides = Object.keys(data).length;

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      interval={7500}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={30}
    >
      {data.map((item, index) => (
        <BackgroundImage
          fluid={item.node.image.fluid}
          className={classes.heroImage}
        >
          <Grid container className={classes.gridContainer}>
            {item.node.headerLeft && (
              <>
                <Grid item xs={6}>
                  <h1 className={classes.headerLeft}>{item.node.headerLeft}</h1>
                </Grid>
                <Container>
                  <h1 className={classes.slideNr}>0{index + 1}</h1>
                </Container>
              </>
            )}
            {item.node.headerCenter && (
              <Grid
                item
                xs={12}
                spacing={3}
                className={classes.headerCenterContainer}
              >
                <div>
                  <h1 className={classes.headerCenter}>
                    {item.node.headerCenter}
                  </h1>
                </div>
              </Grid>
            )}
            {item.node.headerRight && (
              <>
                <Container
                  xs={6}
                  spacing={3}
                  className={classes.topContainer}
                  // maxWidth={false}
                >
                  <h1 className={classes.headerRight}>
                    {item.node.headerRight}
                  </h1>
                  <div
                    className={classes.subHeaderLeftInner}
                    dangerouslySetInnerHTML={{
                      __html: item.node.subHeaderLeft.childMarkdownRemark.html,
                    }}
                  />
                </Container>
                {/* <Container className={classes.subHeaderWrapper}> */}
                {/* <div
                  className={classes.subHeaderLeftInner}
                  dangerouslySetInnerHTML={{
                    __html: item.node.subHeaderLeft.childMarkdownRemark.html,
                  }}
                /> */}
                {/* </Container> */}
                <Container>
                  <h1 className={classes.slideNr}>0{index + 1}</h1>
                </Container>
              </>
            )}
          </Grid>
        </BackgroundImage>
      ))}
    </Carousel>
  );
};

export default HeroCarousel;
