import React from 'react';
import { graphql, Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import CookieConsent from 'react-cookie-consent';
import NewsSection from '../components/newsSection';
import HeroCarousel from '../components/carousel';
import SEO from '../components/seo';
import ogimage from '../images/metaimage.jpg';

const RootIndex = ({ data }) => {
  const news = data.allContentfulBlogPost.edges;
  const banners = data.allContentfulHeroBanner.edges;

  return (
    <>
      <SEO
        title="Vow ASA | Renewable energy"
        ogimage={`https://www.vowasa.com${ogimage}`}
      />
      <HeroCarousel showThumbs={false} data={banners} />
      <Container>
        <NewsSection data={news} />
        <CookieConsent
          location="bottom"
          buttonText="Accept cookies"
          cookieName="myAwesomeCookieName2"
          style={{ background: 'rgb(25,25,25,0.78)' }}
          buttonStyle={{
            color: '#fff',
            backgroundColor: '#EC193E',
            padding: '8px 22px',
            fontSize: '12px',
          }}
          expires={150}
        >
          This website uses cookies. To learn more about how we use cookies see
          our <Link to="/cookie-policy">cookies policy</Link>
        </CookieConsent>
      </Container>
    </>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHeroBanner(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          image: image {
            fluid(
              quality: 90
              maxWidth: 1921
              maxHeight: 1002
              resizingBehavior: PAD
              background: "transparent"
            ) {
              ...GatsbyContentfulFluid
            }
          }
          headerLeft
          headerCenter
          headerRight
          subHeaderLeft {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(quality: 90, maxWidth: 300, maxHeight: 200) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

// Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
