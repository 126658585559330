import React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { shape, string, arrayOf } from 'prop-types';
import NewsCard from './newsCard';
import Header1 from './header1';
import Button from './button';

const NewsSection = ({ data }) => (
  <>
    <Header1 title="LATEST NEWS" headerContainer={{ marginTop: 30 }} />
    <Grid
      container
      direction="row"
      justify="center"
      spacing={4}
      style={{ paddingTop: '60px' }}
    >
      {data.map(item => (
        <Grid item xs={12} sm>
          <NewsCard data={item.node} />
        </Grid>
      ))}
    </Grid>
    <Button
      component={Link}
      label="More news"
      to="/news-and-media/news"
      buttonContainer={{ marginBottom: 20 }}
    />
  </>
);

NewsSection.propTypes = {
  data: arrayOf(
    shape({
      node: shape({
        title: string,
        shortDescription: string,
        heroImage: shape({
          fluid: shape(),
        }),
      }),
    })
  ).isRequired,
};

export default NewsSection;
