import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import BackgroundImage from 'gatsby-background-image';
import { shape, string } from 'prop-types';
import Img from 'gatsby-image';
import arrowBlk from '../images/arrowBlk.png';
import { COLOR_CORAL } from '../utils/constants';

const useStyles = makeStyles(() => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '160px',
    '@media (max-width:600px)': {
      height: '140px',
      marginBottom: 60,
    },
  },
  flexItem: {
    display: 'flex',
    backgroundColor: 'lightred',
  },
  link: {
    textDecoration: 'none !important',
  },
  title: {
    marginBottom: 8,
    lineHeight: '1.4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    fontWeight: 'bold',
  },
  description: {
    margin: 0,
    fontSize: '0.8em',
  },
  readMore: {
    fontSize: '0.8em',
    textAlign: 'center',
    display: 'flex',
    lineHeight: '1em',
    '@media (max-width:600px)': {
      marginTop: '-50px',
    },
  },
  titleHeight: {
    height: 90,
    '@media (max-width:600px)': {
      height: 30,
    },
  },
  line: {
    display: 'block',
    width: 30,
    height: '2px',
    border: 0,
    borderTop: `4px solid${COLOR_CORAL}`,
    margin: '0.5em 0',
    padding: 0,
  },
}));

const NewsCard = ({ data }) => {
  const classes = useStyles();
  return (
    <Link to={`/blog/${data.slug}`} className={classes.link}>
      {data.heroImage.fluid && <Img fluid={data.heroImage.fluid} />}
      <div className={classes.flexContainer}>
        <div className={classes.titleHeight}>
          <h4 className={classes.title}>{data.title}</h4>
        </div>
        <div>
          <p className={classes.description}>
            {data.shortDescription || data.publishDate}
          </p>
          <hr className={classes.line} />
        </div>

        <div className={classes.readMore}>
          <span>Read more</span>
          {/* <img
            src={arrowBlk}
            style={{
              width: 50,
              height: 7,
              paddingLeft: 10,
              paddingTop: 2,
              verticalAlign: 'baseline',
            }}
          /> */}
        </div>
      </div>
    </Link>
  );
};

NewsCard.propTypes = {
  data: shape({
    title: string,
    shortDescription: string,
    heroImage: shape({
      fluid: shape(),
    }),
  }).isRequired,
};

export default NewsCard;
